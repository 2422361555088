import React, { useEffect, useRef } from 'react';

const ParticleBackground = ({ isDarkMode }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let particles = [];
    let animationFrameId;

    const getRandomGradientColor = () => {
      const colors = [
        'rgba(59, 130, 246, 0.8)',  // blue-500
        'rgba(139, 92, 246, 0.8)',  // purple-500
        'rgba(236, 72, 153, 0.8)'   // pink-500
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    };

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const createParticle = (x, y) => ({
      x,
      y,
      size: Math.random() * 4 + 2,
      speedX: Math.random() * 2 - 1,     // Faster movement
      speedY: Math.random() * 2 - 1,     // Faster movement
      color: getRandomGradientColor()
    });

    const initParticles = () => {
      particles = [];
      const numberOfParticles = Math.floor((canvas.width * canvas.height) / 25000); // Fewer particles
      
      for (let i = 0; i < numberOfParticles; i++) {
        particles.push(createParticle(
          Math.random() * canvas.width,
          Math.random() * canvas.height
        ));
      }
    };

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      particles.forEach(particle => {
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fillStyle = particle.color;
        ctx.fill();

        // Update position
        particle.x += particle.speedX;
        particle.y += particle.speedY;

        // Wrap around instead of bouncing
        if (particle.x < 0) particle.x = canvas.width;
        if (particle.x > canvas.width) particle.x = 0;
        if (particle.y < 0) particle.y = canvas.height;
        if (particle.y > canvas.height) particle.y = 0;
      });

      animationFrameId = requestAnimationFrame(drawParticles);
    };

    resizeCanvas();
    initParticles();
    drawParticles();

    window.addEventListener('resize', () => {
      resizeCanvas();
      initParticles();
    });

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [isDarkMode]);

  return (
    <canvas
      ref={canvasRef}
      className="fixed top-0 left-0 w-full h-full"
      style={{ 
        opacity: 1,
        mixBlendMode: isDarkMode ? 'screen' : 'multiply',
        background: 'transparent'
      }}
    />
  );
};

export default ParticleBackground;
