import React, { useState, useEffect } from 'react';
import { Github, Linkedin, Mail, Terminal, Code, Database, ExternalLink, Sun, Moon } from 'lucide-react';
import ParticleBackground from './ParticleBackground';


const Portfolio = () => {
  const [isVisible, setIsVisible] = useState({
    hero: false,
    about: false,
    projects: false,
    contact: false
  });

  const [isDarkMode, setIsDarkMode] = useState(true);
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    // Set initial visibility after a short delay for entrance animation
    setTimeout(() => {
      setIsVisible({
        hero: true,
        about: true,
        projects: true,
        contact: true
      });
    }, 100);

    // Handle scroll progress
    const handleScroll = () => {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const projects = [
    {
      title: "Basketball Tournament App",
      description: "Basketball app that shows live tournament scores, news, and game details all in one place.",
      tech: ["Flutter", "Firebase", "Google Adsense"],
      link: "#"
    },
    {
      title: "Basketball Player Stat Recording App",
      description: "Basketball player stat recording app with quick2learn UI, and inbuilt stat computation",
      tech: ["Flutter", "Firebase", "Google Adsense"],
      link: "#"
    },
    {
      title: "Basketball Drills Recording App",
      description: "Basketball training app with drill recording and performance tracking.",
      tech: ["Flutter"],
      link: "#"
    }
  ];

  return (
    <div className={`min-h-screen transition-colors duration-300 ${
      isDarkMode ? 'bg-black text-white' : 'bg-white text-black'
    }`}>
      <ParticleBackground isDarkMode={isDarkMode} />
      
      {/* Scroll Progress Bar */}
      <div className="fixed top-0 left-0 w-full h-1 bg-transparent">
        <div 
          className="h-full bg-blue-500 transition-all duration-150"
          style={{ width: `${scrollProgress}%` }}
        />
      </div>

      {/* Theme Toggle Button */}
      <button
        onClick={toggleTheme}
        className={`fixed top-4 right-4 p-2 rounded-full transition-colors duration-300 ${
          isDarkMode 
            ? 'bg-gray-800 hover:bg-gray-700' 
            : 'bg-gray-200 hover:bg-gray-300'
        }`}
      >
        {isDarkMode ? (
          <Sun className="w-6 h-6 text-yellow-400" />
        ) : (
          <Moon className="w-6 h-6 text-gray-600" />
        )}
      </button>

      <div className="max-w-3xl mx-auto px-6 py-20">
        {/* Hero Section */}
        <section className={`min-h-screen flex flex-col items-center justify-center text-center space-y-8 transform transition-all duration-1000 ${
          isVisible.hero ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
        }`}>
          <div className="space-y-6">
            <h1 className="text-7xl font-bold bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent animate-gradient">
              Sagisvarman
            </h1>
            <p className={`text-2xl ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              Flutter Dev
            </p>
            <div className="flex justify-center space-x-6 pt-4">
              <a href="#" className="hover:text-blue-500 transition-colors duration-300 hover:scale-110 transform">
                <Github className="w-8 h-8" />
              </a>
              <a href="#" className="hover:text-blue-500 transition-colors duration-300 hover:scale-110 transform">
                <Linkedin className="w-8 h-8" />
              </a>
              <a href="#" className="hover:text-blue-500 transition-colors duration-300 hover:scale-110 transform">
                <Mail className="w-8 h-8" />
              </a>
            </div>
          </div>
          
          <div className="mt-12 animate-bounce">
            <Terminal className="w-12 h-12 text-blue-500" />
          </div>
        </section>

        {/* About Section */}
        <section className={`min-h-screen flex flex-col items-center justify-center text-center space-y-8 transform transition-all duration-1000 ${
          isVisible.about ? 'translate-x-0 opacity-100' : '-translate-x-10 opacity-0'
        }`}>
          <h2 className="text-4xl font-bold">About Me</h2>
          <p className={`text-xl ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} max-w-2xl leading-relaxed`}>
            I do mobile apps, specifically for Android. More specifically, Flutter.
          </p>
          
          <div className="grid grid-cols-2 md:grid-cols-3 gap-6 mt-12">
            {/* Tech stack cards */}
            <div className={`p-6 rounded-xl text-center transform transition-all duration-300 hover:scale-105 hover:shadow-xl ${
              isDarkMode ? 'bg-gray-900 hover:bg-gray-800' : 'bg-gray-100 hover:bg-gray-200'
            }`}>
              <Code className="w-8 h-8 mx-auto mb-4 text-blue-500" />
              <h3 className="text-lg font-semibold mb-2">Frontend</h3>
              <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>Flutter</p>
            </div>
            <div className={`p-6 rounded-xl text-center transform transition-all duration-300 hover:scale-105 hover:shadow-xl ${
              isDarkMode ? 'bg-gray-900 hover:bg-gray-800' : 'bg-gray-100 hover:bg-gray-200'
            }`}>
              <Database className="w-8 h-8 mx-auto mb-4 text-purple-500" />
              <h3 className="text-lg font-semibold mb-2">Backend</h3>
              <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>Firebase</p>
            </div>
            <div className={`p-6 rounded-xl text-center transform transition-all duration-300 hover:scale-105 hover:shadow-xl ${
              isDarkMode ? 'bg-gray-900 hover:bg-gray-800' : 'bg-gray-100 hover:bg-gray-200'
            }`}>
              <Terminal className="w-8 h-8 mx-auto mb-4 text-pink-500" />
              <h3 className="text-lg font-semibold mb-2">Documentation</h3>
              <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>Testing, Flowcharts, Technical Writing</p>
            </div>
          </div>
        </section>

        {/* Projects Section */}
        <section className={`min-h-screen flex flex-col items-center justify-center space-y-16 transform transition-all duration-1000 ${
          isVisible.projects ? 'translate-x-0 opacity-100' : 'translate-x-10 opacity-0'
        }`}>
          <h2 className="text-4xl font-bold text-center">Featured Projects</h2>
          
          <div className="space-y-12 w-full">
            {projects.map((project, index) => (
              <div 
                key={index}
                className={`group p-8 rounded-2xl transform transition-all duration-300 hover:scale-105 ${
                  isDarkMode 
                    ? 'bg-gray-900 hover:bg-gray-800' 
                    : 'bg-gray-100 hover:bg-gray-200'
                }`}
              >
                <div className="flex justify-between items-start mb-4">
                  <h3 className="text-2xl font-semibold group-hover:text-blue-500 transition-colors duration-300">
                    {project.title}
                  </h3>
                  <ExternalLink className={`w-6 h-6 ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-600'
                  } group-hover:text-blue-500 transition-colors duration-300`} />
                </div>
                <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                  {project.description}
                </p>
                <div className="flex flex-wrap gap-2 mt-6">
                  {project.tech.map((tech, techIndex) => (
                    <span
                      key={techIndex}
                      className={`px-3 py-1 rounded-full text-sm transform transition-all duration-300 hover:scale-105 ${
                        isDarkMode 
                          ? 'bg-gray-800 text-gray-300 group-hover:bg-gray-700' 
                          : 'bg-gray-200 text-gray-700 group-hover:bg-gray-300'
                      }`}
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Contact Section */}
        <section className={`min-h-screen flex flex-col items-center justify-center text-center space-y-8 transform transition-all duration-1000 ${
          isVisible.contact ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
        }`}>
          <h2 className="text-4xl font-bold">Let's Connect</h2>
          <p className={`text-xl ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            My Stuffs
          </p>
          
          <div className="space-y-4 mt-8">
            <a 
              href="mailto:"
              className={`flex items-center space-x-4 text-lg ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              } hover:text-blue-500 transition-all duration-300 transform hover:translate-x-2`}
            >
              <Mail className="w-6 h-6" />
              <span>will add this later </span>
            </a>  
            <a 
              href="#"
              className={`flex items-center space-x-4 text-lg ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              } hover:text-blue-500 transition-all duration-300 transform hover:translate-x-2`}
            >
              <Github className="w-6 h-6" />
              <span>github.com/sagisvarman</span>
            </a>
            <a 
              href="#"
              className={`flex items-center space-x-4 text-lg ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              } hover:text-blue-500 transition-all duration-300 transform hover:translate-x-2`}
            >
              <Linkedin className="w-6 h-6" />
              <span>linkedin.com/in/sagisvarman</span>
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Portfolio;
